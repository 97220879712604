function parseItemName(item) {
    let name                = item.name;
    let market_hash_name    = item.market_hash_name;

    let working = market_hash_name;
    
    let stattrak = false;
    let wear = "";

    if (working.indexOf('StatTrak™') > -1) {
        stattrak = true;
        working = working.replace('★ StatTrak™', '');
        working = working.replace('StatTrak™', '');
    }

    if (working.indexOf('(Factory New)') > -1) {
        wear = "Factory New";
        working = working.replace('(Factory New)', '')
    } 
    if (working.indexOf('(Well-Worn)') > -1) {
        wear = "Well-Worn";
        working = working.replace('(Well-Worn)', '')
    } 
    if (working.indexOf('(Battle-Scarred)') > -1) {
        wear = "Battle-Scarred";
        working = working.replace('(Battle-Scarred)', '')
    } 
    if (working.indexOf('(Minimal Wear)') > -1) {
        wear = "Minimal Wear";
        working = working.replace('(Minimal Wear)', '')
    } 
    if (working.indexOf('(Field-Tested)') > -1) {
        wear = "Field-Tested";
        working = working.replace('(Field-Tested)', '')
    } 

    return {
        stattrak,
        wear
    };
}

export default parseItemName