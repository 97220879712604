<template>
    <div class="sticky-top" style="z-index: 100;" >

        <!-- Game selector -->
        <div class="my-2" style="background-color:white;">
            <div class="">
                <div style="" class="d-flex justify-between">
                    <div class="d-flex ml-5">
                        <div class="my-auto">
                            <div class="btn sell-game-button clickable" :class="{ active: isSteamSelected, 'font-weight-bold': isSteamSelected }" @click="$emit('selected-inventory', 'steam'); isSteamSelected = true">Steam Inventory ({{(steamItemCount)}})</div>
                            <div class="btn sell-game-button clickable ml-3" :class="{ active: !isSteamSelected, 'font-weight-bold': !isSteamSelected }" @click="$emit('selected-inventory', 'site'); isSteamSelected = false">Site Inventory ({{siteItemCount}})</div>
                        </div>


                        <div class='d-flex'>
                            <div class="mx-2 game-icon-selector" :class="selectedGame == 'TF2' || selectedGame == 'All'  ? 'active' : ''" @click="selectGame('TF2')"><img src="@/assets/tf2logosm.jpg"></div>
                            <!-- <div class="mx-2 game-icon-selector" :class="selectedGame == 'CS:GO' || selectedGame == 'All'  ? 'active' : ''" @click="selectGame('CS:GO')"><img src="@/assets/csgologosm.jpg"></div> -->
                            <!-- <div class="mx-2 game-icon-selector" :class="selectedGame == 'Rust' || selectedGame == 'All'  ? 'active' : ''" @click="selectGame('Rust')"><img src="@/assets/rustlogosm.jpg"></div> -->
                            <!-- <div class="mx-2 game-icon-selector" :class="selectedGame == 'Dota 2' || selectedGame == 'All'  ? 'active' : ''" @click="selectGame('Dota 2')"><img src="@/assets/dota2logosm.jpg"></div> -->
                        </div>

                        <div class="dropdown my-auto">
                            <a class="" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span 
                                class="font-weight-light mr-2" 
                                style="font-family: Rubik;font-style: normal;font-weight: normal;font-size: 14px;color: #545454; cursor: pointer;"
                                >Sort by:</span> 
                                <span class="rubik-14 font-weight-bold clickable c-navy">{{sortBy}}</span>
                                <font-awesome-icon style="color: #545454; margin-left:10px;" icon="angle-down" />
                                
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <!-- <a class="dropdown-item rubik" href="#0" @click="sort('Best Deal')">Best Deal</a> -->
                                <a class="dropdown-item rubik" href="#0" @click="sort('Name (A-Z)')">Name (A-Z)</a>
                                <a class="dropdown-item rubik" href="#0" @click="sort('Name (Z-A)')">Name (Z-A)</a>
                                <a class="dropdown-item rubik" href="#0" @click="sort('Price (L-H)')">Price (L-H)</a>
                                <a class="dropdown-item rubik" href="#0" @click="sort('Price (H-L)')">Price (H-L)</a>
                            </div>
                        </div>
                    </div>
                    <div style="position: absolute;right: 440px;">
                        <div class="my-auto">
                            <div class="item-search input-group ">
                                <input v-model="search" class="form-control search-me" type="text" placeholder="Search My Inventory" @change="$emit('search', search)">
                                <span class="item-search-icon">
                                    <font-awesome-icon icon="search" />
                                </span>
                                <div></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'steamItemCount',
        'siteItemCount',

    ],
    data() {
        return {
            isSteamSelected: true,
            availableGames: [ 'TF2', 'CS:GO', 'Rust', 'Dota 2'],
            selectedGame: "CS:GO",
            search: "",
            sortBy: "Price (H-L)"
        }
    },
    computed: {
        games() {
            let data = [...this.availableGames] 
            data.splice(data.indexOf(this.selectedGame), 1);
            return data;
        }
    },
    methods: {
        selectGame(game) {
            console.log("Selecting new game " + game)
            this.$emit('selected-game', game);
            console.log("Emitted")
            this.selectedGame = game;
        },
        sort(direction) {
            this.sortBy = direction;
            this.$emit('sort-by', direction);
        }
    }
}
</script>

<style lang="scss">

</style>