<template>
  <div style=" overflow-x: hidden;" class="h-100">
    <!-- Top filter bar -->
    <div>
      <FilterBar
        :siteItemCount="siteItemCount"
        :steamItemCount="steamItemCount"
        v-on:selected-game="changeGame"
        v-on:search="changeSearch"
        @selected-inventory="changeInventory"
        @sort-by="sortBy=$event"
      />

      <div class="d-flex h-100 w-100">
        <!-- TODO: Figure out this height dynamically (either better CSS or calc js) -->
        <div class="items-grid ml-5 w-100" :style="{ height: calculatedHeight + 'px' }" style="overflow-y: auto" ref="itemGrid">
          <div class="d-flex" v-for="(row, i) in renderableItems" v-bind:key="i">
            <Item
              class="mb-3 mr-3"
              :item="item"
              v-for="item in row"
              :ref="item.id"
              v-bind:key="item.appid +'_' + item.contextid +'_'+ item.id + '_' + i"
              :sellable="true"
              :instantSellable="true"
              @selected="addItem(item.id)"
              @deselected="removeItem(item.id)"
            ></Item>
          </div>
          <div
            class="text-center mx-auto mt-5"
            v-if="errorLoading"
          >{{ errorLoading }}</div>

          <div v-if="loading" id="loader"></div>

          <div v-else class="text-center mx-auto mt-5 mb-5">
            <div v-if="renderableItems.length == 0">Looks like you don't have any {{selectedGame}} items! Get trading!</div>
            <!-- <button class="btn btn-primary" v-if="allItems.length > maxLoad" @click="maxLoad += 12">Load more</button> -->

            <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
              <div slot="spinner">Loading...</div>
              <div slot="no-more">No more</div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>

        <div style="width: 600px; position: relative; right:0;" :style="{ height: calculatedHeight + 'px' }">
          <div style="height: 100%">
            <div class="h-100 d-flex flex-column" style>
              <div class style>
                <div
                  class="p-2 bg-navy text-white d-flex justify-content-between"
                  style="margin: 0;"
                >
                  <div
                    style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
                  >Item</div>
                  <div class="d-flex mr-5">
                    <div
                      style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
                    >List Price</div>
                    <div
                      class="ml-5"
                      style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
                    >Net</div>
                  </div>
                </div>
              </div>

              <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;">
                <div v-for="game of itemsToList" v-bind:key="game.id">
                  <div class="row p-2 bg-navy text-white">
                    <span
                      class="ml-4"
                      style="font-family: Rubik;font-style: normal;font-weight: bold;font-size: 14px;line-height: 14px;letter-spacing: -0.5px;color: #FFFFFF;"
                    >{{game.name}}</span>
                    <span
                      class="ml-3"
                      style="font-family: Rubik;font-style: normal;font-weight: normal;font-size: 14px;line-height: 14px;letter-spacing: -0.5px;color: #FFFFFF;"
                    >({{game.qty}} Items)</span>
                  </div>

                  <div v-for="(item, i) of game.items" v-bind:key="item.id + '_' + i">
                    <div class="d-flex p-2 justify-content-between" style="overflow-x: none; cursor: pointer;" @click="openSellModal(item.id)">
                      <!-- <div class="p-1"><img :src="item.image || ('https://steamcommunity-a.akamaihd.net/economy/image/'+item.icon_url+'/128fx128fdpx2x')" style="max-height: 70px; max-width: 150px" class=""></div> -->
                      <div class="my-auto">
                        <div>
                          <span
                            
                            style="cursor: pointer;font-family: Roboto; font-style: normal; font-weight: 900; font-size: 14px; color: #545454;"
                          >{{item.name}} <span v-if="item.type == 'COMMODITY'">({{item.quantity}})</span></span>
                        </div>
                        <div>
                          <span
                            v-if="item.stattrak"
                            style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #C46C4F;"
                          >StatTrak™</span>
                          <span
                            v-if="item.wear"
                            style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #7BAA64;"
                          > {{item.wear}}</span>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="badge my-auto ml-2">
                          <img src="@/assets/info.svg" alt />
                        </div>
                        <div
                          class="my-auto"
                          style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E;"
                        >
                          <div
                            @click="selectedSideItemId = item.id != selectedSideItemId ? item.id : ''"
                            class="my-auto"
                            style="width:70px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E; padding-left: 15px; padding-right: 15px;"
                          >{{numeral(item.listPrice).format('$0,0.00')}}</div>
                        </div>
                        <div
                          class="my-auto"
                          style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E;"
                        >
                          <div
                            @click="selectedSideItemId = item.id != selectedSideItemId ? item.id : ''"
                            class="my-auto"
                            style="width:70px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E; padding-left: 15px; padding-right: 15px;"
                          >{{numeral(item.net * item.quantity).format('$0,0.00')}}</div>
                        </div>

                        <div class="my-auto p-2 clickable" @click.stop="removeItem(item.id)">
                          <img src="@/assets/trash.svg" alt />
                        </div>
                      </div>
                    </div>
                    <div class="hr" v-if="i != game.items.length -1"></div>
                  </div>
                </div>
              </div>

              <div class="flex-shrink-0">
                <div
                  class="d-flex justify-content-around p-2"
                  style="height:70px; background-color: #EAEAEA"
                >
                  <div class="m-2 p-2">{{allItemsCount}} item(s)</div>
                  <div class="m-2 p-2">Net: {{numeral(subtotal).format('$0,0.00')}}</div>
                  <div class="m-2 p-2">Total: {{numeral(total).format('$0,0.00')}}</div>
                </div>

                <div
                  class="d-flex p-2 pt-3 justify-content-around bg-navy"
                  style="height:70px;"
                >
                  <div class="text-white m-1" @click="removeItems" style="cursor: pointer;">Clear all</div>
                  <div>
                    <button
                      class="btn btn-block btn-lg btn-secondary"
                      @click="listItems"
                      :disabled="isDisabled"
                    >List {{allItemsCount}} items for {{numeral(total).format('$0,0.00')}}</button>
                  </div>
                </div>
              </div>

              <div class="modal modal-fade" tabindex="-1" role="dialog" id="sellmodal">
                <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div class="modal-content"  v-if="sideItem">
                        <div class="modal-header text-center">

                              <h5 class="modal-title col-12 text-center d-flex">
                                <div style="left: 0px; height: 0px; position: absolute; top: -13px;"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + sideItem.image + '/256fx256f'" alt="" style="height:60px;"></div>
                                
                                <div class="px-2" style="margin-left:45px; margin-top:5px;" v-resize-text="{ 'ratio': 2.5 }">{{ sideItem.market_hash_name }}</div>
                                
                                <div class="item-tooltip-container">
                                  <span class="item-name">
                                    <div class="badge my-auto ml-2">
                                      <img src="@/assets/info.svg" alt />
                                    </div>
                                  </span>
                                  <Item class="item-tooltip" :key="sideItem.id" :item="sideItem"></Item>
                                </div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                              </h5>
                        </div>
                        <div class="modal-body p-3">
                          <div class="d-flex justify-content-center my-2">
                            <div class="d-flex">
                              <div class="input-group-prepend">
                                    <span 
                                        class="input-group-text brl" 
                                        id="basic-addon1" 
                                        style="
                                            font-size: 1.3rem;
                                            border-top-right-radius: 0px;
                                            border-bottom-right-radius: 0px;
                                        "
                                    >$</span>
                                </div>
                                <currency-input
                                    :value="sideItem.listPrice"
                                    @change="sideItem.listPrice = $event"
                                    @priceChanged="sideItem.listPrice = $event"
                                    v-on:enter="closeSellModal"
                                    class=""
                                    style="
                                        font-size: 1.3rem;
                                        text-align: center;
                                        border-top-right-radius: 15px; 
                                        border-bottom-right-radius: 15px; outline: none;
                                        border: 1px solid #ced4da;
                                        background-color: #e9ecef;
                                        color: #7BAA64;
                                    "
                                    :options="{
                                        currency: 'USD',
                                        currencyDisplay: 'hidden',
                                        hideCurrencySymbolOnFocus: false,
                                        hideGroupingSeparatorOnFocus: false,
                                        hideNegligibleDecimalDigitsOnFocus: false,
                                        useGrouping: true,
                                        valueRange: { min: 0, max: 100000 },
                                    }"
                                />
                                <template v-if="sideItem.type == 'COMMODITY'">
                                  <h3 class="my-auto mx-2" >X</h3>
                                  
                                  <div class="input-group-prepend">
                                      <span 
                                          class="input-group-text brl" 
                                          id="basic-addon1" 
                                          style="
                                              font-size: 1.3rem;
                                              border-top-right-radius: 0px;
                                              border-bottom-right-radius: 0px;
                                          "
                                      >QTY</span>
                                  </div>
                                  <currency-input
                                      :value="sideItem.quantity"
                                      @change="sideItem.quantity = $event"
                                      v-on:enter="closeSellModal"
                                      class=""
                                      style="
                                          font-size: 1.3rem;
                                          text-align: center;
                                          border-top-right-radius: 15px; 
                                          border-bottom-right-radius: 15px; outline: none;
                                          border: 1px solid #ced4da;
                                          background-color: #e9ecef;
                                          color: #7BAA64;
                                      "
                                      :options="{
                                          currency: 'USD',
                                          currencyDisplay: 'hidden',
                                          hideCurrencySymbolOnFocus: false,
                                          hideGroupingSeparatorOnFocus: false,
                                          hideNegligibleDecimalDigitsOnFocus: false,
                                          useGrouping: true,
                                          valueRange: { min: 0, max: 100000 },
                                      }"
                                  />
                              </template>
                            </div>
                            <button class="btn btn-orange ml-3" @click="selectItem(selectedSideItemId)" data-dismiss="modal" :disabled="sideItem.listPrice < 0.05 || isNaN(sideItem.quantity)">CONFIRM</button>
                          </div>

                          <div class="d-flex justify-content-around mt-3">
                            <div>
                              <div class="mx-1">
                                <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px; padding: 5px 0px; background: #f3f3f3;">
                                  <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Average Sale</div>
                                  <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(avgPrice).format('$0,0.00')}}</div>
                                </div>
                                <button class="btn btn-block" @click="changeListPrice(sideItem, avgPrice);selectedSidesideItem = null;" :disabled="!avgPrice" :class="avgPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                              </div>
                            </div>
                            <div>
                              <div class="mx-1">
                                <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                                  <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Lowest Listing</div>
                                  <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(minPrice).format('$0,0.00')}}</div>
                                </div>
                                <button class="btn btn-block" @click="changeListPrice(sideItem, minPrice);selectedSidesideItem = null;" :disabled="!minPrice" :class="minPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                              </div>
                            </div>
                            <div>
                              <div class="mx-1">
                                <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                                  <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Highest Buyorder</div>
                                  <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(orderPrice).format('$0,0.00')}}</div>
                                </div>
                                <button class="btn btn-block" @click="changeListPrice(sideItem, orderPrice);selectedSidesideItem = null;" :disabled="!orderPrice" :class="orderPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as mock from "@/mock.js";

import parseWear from "../utils/parseWear";
import {parseItems, parseRarity, parseStickers} from "../utils/parseItems";

import FilterBar from "@/components/sell/FilterBar.vue";
import SideBar from "@/components/sell/SideBar.vue";

import numeral from 'numeral';

import api from "@/api.js";
import { mapGetters, mapMutations } from 'vuex';

import events from "@/events.js";

const navbarHeights = 105;
let games = [
    { appId: 730, contextId: 2 }, // csgo
    { appId: 440, contextId: 2 }, // tf2
    { appId: 570, contextId: 2 }, // dota2
    { appId: 433850, contextId: 1 }, //z1br
    { appId: 252490, contextId: 2 }, // rust
]
const validInputs = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."]


export default {
  async mounted() {
    if (!this.user) return this.$router.push('/login')
    events.$on('retryListItems', () => {
      console.log("List items retry called")
      this.listItems();
    });

    this.handleResize();

    // Checking user's steam profile is private before attempting any queries
    let user = this.$store.getters["auth/user"]
    // TODO: Check for inventory not public only
    // Let's hold on implementing this, the communityvisibilitystate doesn't seem to be updating properly - seems like it's stuck on 3 no matter the profile state
    // if (user.steamData.communityvisibilitystate == 1){ // 3 = Public, 1 = Otherwise
    //   Swal.fire("Error", "Your Steam account must be public before attempting to deposit items. Please change your Steam profile's privacy settings.", "error")
    // }

    this.loadItems();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      inventoryType: "steam",
      selectedAppId: 440,
      selectedGame: "TF2",
      loading: true,
      reRenderCount: 0,
      allItems: [],
      maxLoad: 12, // Sort by price relies on listPrice, which is loaded in via Ajax, really we need to append the price before to bring maxLoad down
      errorLoading: false,
      window: {
        width: 0,
        height: 0
      },
      search: "",
      selectedSideItemId: "",
      calculatedHeight: 0,
      infiniteId: 440,
      siteItemCount: 0,
      steamItemCount: 0,
      sortBy: "Price (H-L)",
      // cache: {}
    };
  },
  components: {
    FilterBar,
    SideBar,
  },
  computed: {
    avgPrice() { try { return this.$refs[this.selectedSideItemId][0].avgPrice } catch(e) { return 0 } },
    minPrice() { try { return this.$refs[this.selectedSideItemId][0].minPrice } catch (e) { return 0 } },
    orderPrice() { try { return this.$refs[this.selectedSideItemId][0].orderPrice } catch (e) { return 0 } },
    user() {
        return this.$store.getters["auth/user"];
    },
    sideItem() {
      return this.allItems.find(x => x.id == this.selectedSideItemId)
    },
    selectedItems() {
      return this.allItems.filter(x => x.selected == true).map(item => {
        let taxMultiplier = 0.08;
        if (item.listPrice > 30) taxMultiplier = 0.06;
        
        item.tax = item.listPrice * taxMultiplier;
        item.net = item.listPrice - item.tax;
        return item;
      });
    },
    filteredItems() {
      if (!this.allItems) return;
      console.log("Re-loading renderable list");
      this.reRenderCount;
      let seenCommodities = {}
      let items = JSON.parse(JSON.stringify(this.allItems));
      
      items = items.filter(item => {
        let steamWanted       = this.inventoryType == "steam";
        let siteWanted        = this.inventoryType == "site";

        let isSteamItem       = item.location == "STEAM_INVENTORY";
        let isSelectedGame    = item.appId == this.selectedAppId;
        
        if (item.selected) return false;
        if (steamWanted && !isSteamItem) return false;
        if (siteWanted && isSteamItem) return false;
        
        if (!isSelectedGame) return false;

        if (this.search) {
          let name = item.name || item.market_name || item.market_hash_name || "";
          let foundResult = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;

          if (!foundResult) return false; // Filter out any that do not match the result
        }

        return true;
      });

      console.log(`Filtering commodities out: ${this.allItems.length}`)
      items = items.filter(item => {
        if (item.type != "COMMODITY") return true;
      
        if (seenCommodities[item.market_hash_name]) {
          seenCommodities[item.market_hash_name]++;
          return false;
        } else {
          seenCommodities[item.market_hash_name] = 1;
        }

        return true;
      });

      for (let item of items) {
        item.quantity = seenCommodities[item.market_hash_name] || 1;
      }

      console.log(seenCommodities)
      console.log(`${items.length} after filterng`);

      console.log(`${items.length} after filterng again, now sorting`);

      items = items.sort((itemA, itemB) => {
        if (this.sortBy == "Price (H-L)") {
          if (parseFloat(itemA.listPrice) > parseFloat(itemB.listPrice)) {
            return -1;
          } else if (parseFloat(itemA.listPrice) < parseFloat(itemB.listPrice)) {
            return 1;
          } else {
            return 0;
          }
        } else if (this.sortBy == "Price (L-H)") {
          if (parseFloat(itemA.listPrice) > parseFloat(itemB.listPrice)) {
            return 1;
          } else if (parseFloat(itemA.listPrice) < parseFloat(itemB.listPrice)) {
            return -1;
          } else {
            return 0;
          }
        } else if (this.sortBy == "Name (A-Z)") {
          if (this.parseNiceMarketHashName(itemA.name) > this.parseNiceMarketHashName(itemB.name)) {
            return 1;
          } else if (this.parseNiceMarketHashName(itemA.name) < this.parseNiceMarketHashName(itemB.name)) {
            return -1;
          } else {
            return 0;
          }
        } else if (this.sortBy == "Name (Z-A)") {
          if (this.parseNiceMarketHashName(itemA.name) > this.parseNiceMarketHashName(itemB.name)) {
            return -1;
          } else if (this.parseNiceMarketHashName(itemA.name) < this.parseNiceMarketHashName(itemB.name)) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });

      return items;
    },
    renderableItems() {
      let items = this.filteredItems;
      if (!items) return;
      let renderable = items.slice(0, this.maxLoad);
      // Filter based on steam flag
      let itemsPerRow = 5;
      if (this.window.width < 1920) itemsPerRow = 4;
      if (this.window.width < 1700) itemsPerRow = 3;
      // Do the 'binning' into groups of 4
      return renderable.reduce((col, item, i) => {
        if (i % itemsPerRow === 0) col.push([]); // Add a new row

        col[col.length - 1].push(item); // push item to the current row
        return col;
      }, []);
    },
    itemsByGameId() {
      let items = this.selectedItems;

      let games = {};
      for (let item of items) {
        let appId =
          item.appId || item.steamItem.appId || item.steamItem.appid || 0;
        // console.log("Test " + appId);
        games[appId] = games[appId] || [];
        games[appId].push(item);
      }

      return games;
    },
    itemsToList() {
      let itemsByGameId = this.itemsByGameId;

      // console.log("Listables")
      let games = [
        { id: 730, name: "CS:GO", items: itemsByGameId[730] || [], qty: (itemsByGameId[730] || []).reduce((qty, item) => qty + (item.quantity || 1), 0) },
        { id: 440, name: "TF2", items: itemsByGameId[440] || [], qty: (itemsByGameId[440] || []).reduce((qty, item) => qty + (item.quantity || 1), 0) },
        { id: 570, name: "Dota 2", items: itemsByGameId[570] || [], qty: (itemsByGameId[570] || []).reduce((qty, item) => qty + (item.quantity || 1), 0) },
        { id: 252490, name: "Rust", items: itemsByGameId[252490] || [], qty: (itemsByGameId[252490] || []).reduce((qty, item) => qty + (item.quantity || 1), 0) },
        // { id: 433850, name: "Z1:BR", qty: itemsByGameId[433850] || [] }
      ];

      return games.filter(game => game.items.length > 0);
    },
    allItemsCount() {
      return this.itemsToList.reduce(
        (count, game) => (count += game.qty),
        0
      );
    },
    qtyTotal() {
      let count = 0;
      for (let item of this.selectedItems) {
        count += item.quantity;
      }
      return count;
    },
    subtotal() {
      let sum = 0;
      for (let item of this.selectedItems) {
        // Really weird bug where it only works if you include the current line
        // It doesn't enter the for loop unless you check for item.listPrice ??
        // Works for now, leaving it as such
        //if (!item.net || item.net == "$") continue;
        if (!item.listPrice || item.listPrice == "$") continue;
        let price = parseFloat((item.net + "").replace("$", ""));
        sum += (price * item.quantity);
      }
      return sum;
    },
    total() {
      let sum = 0;
      for (let item of this.selectedItems) {
        if (!item.listPrice || item.listPrice == "$") continue;
        let price = parseFloat((item.listPrice + "").replace("$", ""));
        sum += (price * item.quantity);
      }
      return sum;
    },
    isDisabled() {
      return this.total <= 0;
    }
  },
  methods: {
    parseNiceMarketHashName(market_hash_name) {
      return market_hash_name 
        .replace("(Field-Tested)", '')
        .replace("(Minimal Wear)", '')
        .replace("(Battle-Scarred)", '')
        .replace("(Well-Worn)", '')
        .replace("(Factory New)", '')
        .replace("Souvenir", '')
        .replace("★ StatTrak™", '')
        .replace("★", '')
        .replace("StatTrak™", '')
        .replace("", '')
        .trim()
    },
    openSellModal(itemId) {
      this.selectedSideItemId = itemId;
      // Open the modal
      $('#sellmodal').modal('show');
    },
    closeSellModal() {
      $('#sellmodal').modal('hide');
    },
    async loadItems() {
      // let cache = localStorage.getItem(`inventory-cache-${this.selectedAppId}`);
      // if (cache) {
      //   this.allItems = JSON.parse(cache);
      //   localStorage.setItem(`inventory-cache-${this.selectedAppId}`, "[]");
      // }

        try {
          // Clear out
          if (this.allItems.find(x=> x.appId == this.selectedAppId)) return; // we've loaded this game already.
          this.loading = true;
          this.errorLoading = false;

          let game = games.find(x => x.appId == this.selectedAppId);
          let itemsResponse = (await api.getInventory({ all: true, getPrices: true, getFloats: true, appId: game.appId, contextId: game.contextId })).data; // Querying both steam AND our site

          this.steamItemCount = itemsResponse.steam.inventory.length;
          this.siteItemCount = itemsResponse.site.length;
          let prices = itemsResponse.prices;


          console.log("Loaded items from appId " + game.appId + ", size: " + itemsResponse.steam.inventory.length)

          let steamItems = await parseItems(itemsResponse.steam.inventory);

          console.log("Parsing steam items")
          for (let item of steamItems) {
            item.id = item.id || item.steamItem.appid + "_" + item.steamItem.contextid + "_" + item.steamItem.assetid
            item.location = "STEAM_INVENTORY";
            item.quantity = 1;
            item.currency = "$";
            item.marketPrice  = prices[item.market_hash_name];
            item.listPrice    = prices[item.market_hash_name];

          }

          this.allItems = this.allItems.concat(steamItems);
          let siteItems  = await parseItems(itemsResponse.site);
          this.allItems = this.allItems.concat(siteItems);

          this.loading  = false;

          // console.log("Finished loading items. Refreshing cache...")
          localStorage.setItem(`inventory-cache-${this.selectedAppId}`, JSON.stringify(this.allItems));
          if (this.$refs.InfiniteLoading)
            this.$refs.InfiniteLoading.stateChanger.reset(); 

        } catch (e) {
          this.errorLoading = "An unknown error occured while loading your inventory";
          // console.log(itemsResponse);
          console.log(e.message);
          console.log(e.stack);

          // if (
          //   itemsResponse &&
          //   itemsResponse.errors &&
          //   itemsResponse.errors.length > 0
          // ) {
          //   this.errorLoading = itemsResponse.errors[0].message;
          // }
        }
    },
    getValidString(str){
      str = str + ''

      let validString = ""
      for (let i in str){
        let char = str[i];

        if(validInputs.indexOf(char) > -1){
          validString += char
        }
      }
      return validString
    },
    changeInventory(type){
      this.inventoryType = type
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      this.calculatedHeight = this.window.height-navbarHeights
    },
    addItem(itemId) {
      this.openSellModal(itemId);
      console.log("unselecting the ref")
      this.$refs[itemId][0].selected = false;

      this.reRenderCount++;
    },
    selectItem(itemId) {
      let allItem = this.allItems.find(item => item.id == itemId);
      allItem.selected = true;
    },
    removeItem(itemId) {
      let allItem = this.allItems.find(item => item.id == itemId);
      allItem.listPrice = allItem.marketPrice;
      allItem.selected = false;
    },
    removeItems(){
      let itemIds = this.selectedItems.map(x => x.id);
      for(let itemId of itemIds){
        this.removeItem(itemId)
      }
    },
    async listItems() {
      // Verifying user has set their trade offer URL
      let user = this.$store.getters["auth/user"]
      if (user.state == "LIMITED") {
        return Swal.fire({
          title: "Verify Email",
          icon: "info",
          text: "Your account is in a limited state. Please confirm your email to deposit items. "
        });
      }
      
      if (this.$store.getters["auth/user"].state == "HARD_BANNED") {
        return Swal.fire('Account Disabled', "Please contact support", 'danger');
      }

      this.$store.dispatch('stepByStep/startDeposit');

      if(!user.tradeOfferUrl) {
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You must have a trade offer URL set before attempting to trade. Please set it on your <router-link to="//account" v-slot="{ href, route, navigate, isActive, isExactActive }"> <a :href="href" @click="navigate">My Profile</a></router-link> page.`,
        });

        // Swal.fire({
        //   title: "Error",
        //   html: `You must have a trade offer URL set before attempting to trade. Please set it on your <router-link to="//account" v-slot="{ href, route, navigate, isActive, isExactActive }"> <a :href="href" @click="navigate">My Profile</a></router-link> page.`,
        //   icon: "error"
        // })
        return;
      }

      //https://steamcommunity.com/tradeoffer/new/?partner=160911784&token=vb9NrdIi
      let items = this.selectedItems;
      // Making sure items have been selected to trade
      if(items.length == 0){
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          showRetry: false,
          message: `You have not selected any items to trade.`,
        });
        return;
      }

      for (let item of items) {
        let listPrice = numeral(item.listPrice).value()
        if (!listPrice || isNaN(listPrice)) {
          console.log("No list price!");
          this.$store.commit('stepByStep/setError', {
            retryable: false,
            message: `You must set list prices!`,
          });
          return;
        }
        
        if (listPrice > 1000000) {
          this.$store.commit('stepByStep/setError', {
            retryable: false,
            message: `Prices are set too high (Max $100,000)`,
          });
          return;
        }
        if (listPrice < 0.05) {
          this.$store.commit('stepByStep/setError', {
            retryable: false,
            message: `Minimum list price is $0.05`,
          });
          return;
        }
      } 

      // Swal.fire("Hangon", "We're contacting our servers", "info");
      try {
        let apiPayload = items.map(item => {
          return {
            ...item, 
            // Must have a list price
            listPrice: item.listPrice,
            // Optionally has these two, or the appid / assetid / classid / contextid
            market_hash_name: item.market_hash_name,
            quantity: item.quantity,
          }
        });

        let response = await api.listItems(apiPayload);
        
        if (!response.data.success) {
          // Complete. They must have had site items.
          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: 'retryListItems',
            message: `We're unable to list the selected items.`,
          });
          return;
        }

        this.$store.commit('stepByStep/completeStep', {
          // Any data to be applied. (object.merged)
          ...response.data,
        });

        if (!response.data.tradeoffer) {
          this.$store.commit('stepByStep/completeStep', {});
        }

        this.allItems = this.allItems.filter(x => !x.selected);
        // for (let item of this.allItems) {
        //   item.selected = false;
        // }
      } catch (e) {
        let data = e.response || { data: {} };
        data = data.data;

        console.log(e.response);
        console.log(data);

        if (data.error) { 
          if (data.error == "OVERSTOCK") {
            return this.$store.commit('stepByStep/setError', {
              retryable: true,
              retryEvent: 'retryListItems',
              message: `We already have too many ${data.item}'s at $${data.minPrice}. Please try re-listing at $${(data.minPrice - 0.01).toFixed(2)} or lower!`,
            });
          } 
        }

        if (data.message) {
          //   Swal.fire("Uh o", `${data.message}`, `error`);
          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: 'retryListItems',
            message: data.message,
          });
        } else {
          //   Swal.fire("Uh o", `Failed to list: ${e.message}`, `error`);

          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: 'retryListItems',
            message: `Failed to list: ${e.message}`,
          });
        }
      }
    },
    changeListPrice(item, price) {
      // Removing invalid chars
      price = numeral(price).value()
      item.listPrice = Math.round((price + Number.EPSILON) * 100) / 100;
    },
    changeNetPrice(item, price) {
      // // TODO: Tax changes depending if commodity or not, this should be in the DB making this work properly,
      // TODO: Work out the correct list price. Set that.
      let taxMultiplier = item.taxPercent || process.env.VUE_APP_DEFAULT_TAX || 0.05;
      if (item.type == "COMMODITY") taxMultiplier = item.taxPercent || process.env.VUE_APP_DEFAULT_COMMODITY_TAX || 0.02;

      price = numeral(price).value();
      item.listPrice = (Math.round((price / (1-taxMultiplier) + Number.EPSILON) * 100) / 100).toFixed(2) // Change the list price and the .net is auto-computed.
    },
    changeGame(game) {
      console.log("Got new selected game " + game);
      this.selectedGame = game;
      this.selectedAppId = 730;

      if (game == "TF2") this.selectedAppId = 440;
      if (game == "CS:GO") this.selectedAppId = 730;
      if (game == "Dota 2") this.selectedAppId = 570;
      // if (game == "Z1:BR") this.selectedAppId = 433850;
      if (game == "Rust") this.selectedAppId = 252490;
      
      this.loadItems();
    },
    changeSearch(search) {
      console.log("Search event");
      this.search = search;
    },
    numeral,
    infiniteHandler($state) {
      if (!this.filteredItems) return $state.complete();
      console.log("Infinite handler hit! Loading more");
      this.maxLoad += 12;

      // maxLoad is how many items we should load, renderableItems is how many we have available 

      if (this.filteredItems.length > this.maxLoad) {
        return $state.loaded();
        console.log("We've got more!!");
      } 

      console.log(this.maxLoad, this.filteredItems.length)
      $state.complete();
    },
  },
  watch: {
    selectedAppId(newVal) {
      this.infiniteId = newVal;
      this.maxLoad = 12
    },
  }
};
</script>

<style lang="scss" scoped>


/* Tooltip container */
.item-tooltip-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  cursor: pointer;
}

/* Tooltip text */
.item-tooltip-container .item-tooltip {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding: 5px 0; */
  /* border-radius: 6px; */
 
  /* Position the tooltip text - see examples below! */

  top: 0%;
  left: 0px; /* To the left of the tooltip */
  position: absolute;
  z-index: 1;
}

.item-tooltip-container:hover{
  border-bottom: 1px solid white; 

}
/* Show the tooltip text when you mouse over the tooltip container */
.item-tooltip-container:hover .item-tooltip {
  visibility: visible;
}
</style>