<template>
  <!--Side bar -->
  <div style="width: 540px; position: sticky; right:0; argin-right:10px; height:100%;">
    <div style="height: 100%">
      <div class="h-100 d-flex flex-column" style>
        <div class style>
          <div class="p-2 bg-black text-white d-flex justify-content-between" style="margin: 0;">
            <div
              style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
            >Item</div>
            <div class="d-flex mr-5">
              <div
                style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
              >List Price</div>
              <div
                class="ml-5"
                style="font-family: Rubik;font-style: normal;font-weight: 500;font-size: 16px;line-height: 24px;letter-spacing: -0.5px;color: #FFFFFF;"
              >Net</div>
            </div>
          </div>
        </div>

        <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;">
          <div v-for="game of itemsToList" v-bind:key="game.id">
            <div class="row p-2 bg-gray">
              <span
                class="ml-4"
                style="font-family: Rubik;font-style: normal;font-weight: bold;font-size: 14px;line-height: 14px;letter-spacing: -0.5px;color: #FFFFFF;"
              >{{game.name}}</span>
              <span
                class="ml-3"
                style="font-family: Rubik;font-style: normal;font-weight: normal;font-size: 14px;line-height: 14px;letter-spacing: -0.5px;color: #FFFFFF;"
              >({{game.items.length}} Items)</span>
            </div>

            <div v-for="(item, i) of game.items" v-bind:key="item.id + '_' + i">
              <div class="d-flex p-2 justify-content-between" style="overflow-x: none">
                <!-- <div class="p-1"><img :src="item.image || ('https://steamcommunity-a.akamaihd.net/economy/image/'+item.icon_url+'/128fx128fdpx2x')" style="max-height: 70px; max-width: 150px" class=""></div> -->
                <div class="my-auto">
                  <div>
                    <span
                      @click="selectedSideItem = item.id != selectedSideItem ? item.id : ''"
                      style="cursor: pointer;font-family: Roboto; font-style: normal; font-weight: 900; font-size: 14px; color: #545454;"
                    >{{item.name}}</span>
                  </div>
                  <div>
                    <span
                      v-if="item.stattrak"
                      style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #C46C4F;"
                    >StatTrak™</span>
                    <span
                      v-if="item.wear"
                      style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 10px; color: #7BAA64;"
                    >{{item.wear}}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="badge my-auto ml-2">
                    <img src="@/assets/info.svg" alt />
                  </div>
                  <div
                    class="my-auto"
                    style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E;"
                  >
                    <input
                      v-if="item.id == selectedSideItem"
                      v-model="item.listPrice"
                      type="text"
                      @change="changeListPrice(item)"
                      style="border: 0; background-color: #E6E6E6; width:65px; height: 30px; border-radius: 50px; text-align: center; margin-left:10px; margin-right:10px"
                      placeholder="$0.00"
                    />
                    <div
                      v-else
                      @click="selectedSideItem = item.id != selectedSideItem ? item.id : ''"
                      class="my-auto"
                      style="width:70px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E; padding-left: 15px; padding-right: 15px;"
                    >{{item.listPrice}}</div>
                  </div>
                  <div
                    class="my-auto"
                    style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E;"
                  >
                    <input
                      v-if="item.id == selectedSideItem"
                      v-model="item.net"
                      type="text"
                      @change="changeNetPrice(item)"
                      style="border: 0; background-color: #E6E6E6; width:65px; height: 30px; border-radius: 50px; text-align: center; margin-left:10px; margin-right:10px"
                      placeholder="$0.00"
                    />
                    <div
                      v-else
                      @click="selectedSideItem = item.id != selectedSideItem ? item.id : ''"
                      class="my-auto"
                      style="width:70px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; color: #96C77E; padding-left: 15px; padding-right: 15px;"
                    >{{item.net}}</div>
                  </div>

                  <div class="my-auto pb-1 clickable mr-2" @click="removeItem(item.id)">
                    <img src="@/assets/trash.svg" alt />
                  </div>
                </div>
              </div>
              <div v-if="item.id == selectedSideItem">
                <div class="d-flex justify-content-around">
                  <div>
                    <div
                      style="background-color: #606060; height: 30px; width: 75px; padding-top: 3px"
                    >
                      <div class="roboto-8 f-bold">Average Price</div>
                      <div class="roboto-8 f-bold">$20</div>
                    </div>
                    <div class="mt-1" style="width:75px; text-align: center">
                      <div
                        @click="item.net = 20;changeNetPrice(item)"
                        class="btn btn-primary"
                        style="width: 60px;padding-left:0; padding-right:0; height: 20px; line-height:0; font-size: 8px;"
                      >MATCH</div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="background-color: #606060; height: 30px; width: 75px; padding-top: 3px"
                    >
                      <div class="roboto-8 f-bold">Lowest Price</div>
                      <div class="roboto-8 f-bold">$15</div>
                    </div>
                    <div class="mt-1" style="width:75px; text-align: center">
                      <div
                        @click="item.net = 15;changeNetPrice(item)"
                        class="btn btn-primary"
                        style="width: 60px;padding-left:0; padding-right:0; height: 20px; line-height:0; font-size: 8px;"
                      >MATCH</div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="background-color: #606060; height: 30px; width: 75px; padding-top: 3px"
                    >
                      <div class="roboto-8 f-bold">Highest Buy Order</div>
                      <div class="roboto-8 f-bold">$18</div>
                    </div>
                    <div class="mt-1" style="width:75px; text-align: center">
                      <div
                        @click="item.net = 18;changeNetPrice(item)"
                        class="btn btn-primary"
                        style="width: 60px;padding-left:0; padding-right:0; height: 20px; line-height:0; font-size: 8px;"
                      >MATCH</div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="background-color: #606060; height: 30px; width: 75px; padding-top: 3px"
                    >
                      <div class="roboto-8 f-bold">Quantity</div>
                      <div v-if="item.type=='COMMODITY'" class="roboto-8 f-bold">{{item.quantity}}</div>
                      <div v-else class="roboto-8 f-bold">-</div>
                    </div>
                    <div class style="width:75px; text-align: center; margin-top:1px;">
                      <!-- <div @click="item.net = 18;changeNetPrice(item)" class="btn btn-primary" style="width: 60px;padding-left:0; padding-right:0; height: 20px; line-height:0; font-size: 8px;">MATCH</div> -->
                      <input
                        v-if="item.type=='COMMODITY'"
                        v-model="item.quantity"
                        type="text"
                        style="border: 0; background-color: #E6E6E6; width:65px; height: 20px; border-radius: 50px; text-align: center; font-size:8px"
                        placeholder="$0.00"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="hr" v-if="i != game.items.length -1"></div>
            </div>
          </div>
        </div>

        <div class="flex-shrink-0">
          <div
            class="d-flex justify-content-around p-2"
            style="height:70px; background-color: #EAEAEA"
          >
            <div class="m-2 p-2">{{items.length}} item(s)</div>
            <div class="m-2 p-2">Subtotal: {{subtotal}}</div>
            <div class="m-2 p-2">Total {{total}}</div>
          </div>

          <div
            class="d-flex p-2 pt-3 justify-content-around"
            style="height:70px; background-color: #101010"
          >
            <div class="text-white m-1" @click="items = []" style="cursor: pointer;">Clear all</div>
            <div>
              <div
                class="btn btn-block btn-lg btn-secondary"
                @click="listItems"
              >List {{items.length}} items for {{total}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>